<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      no-fade
      spinner-variant="primary"
    >
      <b-card class="text-size-header">
        <b-row>
          <b-col md="6">
            <strong>{{ $t('roles.rol') }}:</strong> {{ rolPermissions != null ? rolPermissions.name : '' }}
          </b-col>
          <b-col md="6">
            <strong>{{ $t('generic.club') }}:</strong> {{ rolPermissions != null && rolPermissions.club != null ? rolPermissions.club.club_name : 'N/A' }}
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

    <b-card
      v-for="(modules, index) in moduleList"
      :key="modules.id"
      class="card-collapse"
    >
      <b-overlay
        :show="show || loading"
        rounded="sm"
        no-fade
        spinner-variant="primary"
      >
        <b-row class="header-collapse align-items-center">
          <b-col
            md="11"
            class="activate"
            @click="collapseMenu('permissions-' + (index + 1), modules.status)"
          >
            <b-form-group style="white-space:nowrap;">
              <b-row>

                <b-col md="2">
                  <strong>{{ modules.name }}</strong>
                </b-col>
                <b-col v-if="checkPermission(['CHANGE_STATUS_PERMISSIONS','ALLOW_ALL'])">
                  <b-form-checkbox
                    v-model="modules.status"
                    name="check-button"
                    class="ml-2"
                    switch
                    :checked="modules.status"
                    :value="1"
                    :unchecked-value="0"
                    inline
                    @change="statusModule(modules)"
                  >
                    <strong>{{ $t('generic.active') }}</strong>
                  </b-form-checkbox>
                </b-col>
                <b-col
                  v-else-if="modules.status === 1"
                >
                  <strong>{{ $t('generic.active') }} </strong>
                  <i
                    class="icon-color-primary icon-0-icons-bright-checkmark"
                  />
                </b-col>
                <b-col
                  v-else
                >
                  <strong>{{ $t('generic.inactive') }} </strong>
                  <i class="icon-0-icons-dark-control-center-nok" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>

        <b-collapse
          :id="'permissions-' + (index + 1)"
          class="mt-2"
          :visible="modules.status == 1"
        >
          <div
            v-for="submodule in modules.submodules"
            :key="submodule.id"
            fluid
          >
            <b-container fluid>
              <b-row class="pt-1 pb-1 mb-1 mt-1 bg-light">
                <b-col md="2">
                  <strong>{{ submodule.name }}</strong>
                </b-col>
                <b-col v-if="checkPermission(['CHANGE_STATUS_PERMISSIONS','ALLOW_ALL'])">
                  <b-form-checkbox
                    v-model="submodule.status"
                    name="check-button"
                    switch
                    :checked="submodule.status"
                    :value="1"
                    :unchecked-value="0"
                    inline
                    @change="statusModule(submodule)"
                  >
                    <strong>{{ $t('generic.active') }}</strong>
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-for="permission in submodule.permissions"
                  :key="permission.id"
                  cols="6"
                  lg="2"
                  md="3"
                  class="mb-1"
                >
                  <label
                    v-b-tooltip.hover.top
                    :title="permission.description"
                    variant="outline-secondary"
                  >
                    {{ permission.name }}
                  </label>&nbsp;
                  <b-form-checkbox
                    v-if="checkPermission(['CHANGE_STATUS_PERMISSIONS','ALLOW_ALL'])"
                    v-model="permission.status"
                    :disabled="!submodule.status"
                    switch
                    :checked="permission.status"
                    :value="1"
                    :unchecked-value="0"
                    @change="statusPermission(submodule, permission)"
                  />
                  <span
                    v-else-if="permission.status === 1"
                  >
                    <!-- <br> -->
                    <i
                      class="icon-color-primary icon-0-icons-bright-checkmark"
                    />
                    <!-- <span>{{ $t('generic.active') }}</span> -->
                  </span>
                  <span
                    v-else
                  >
                    <!-- <br> -->
                    <i class="icon-0-icons-dark-control-center-nok" />
                    <!-- <span>{{ $t('generic.inactive') }}</span> -->
                  </span>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-collapse>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>

import checkPermission from '@/auth/permissions'
import servicesRole from '@/services/roleService'
import servicePermission from '@/services/permissionService'

export default {
  components: {
  },
  data() {
    return {
      show: false,
      loading: false,
      moduleList: [],
      rolPermissions: {
        name: null,
        club: null,
      },
    }
  },
  mounted() {
    if (this.checkPermission(['VIEW_PERMISSIONS'])) {
      this.getRolData(this.$route.params.id)
    } else {
      this.$router.push({ name: 'not-authorized' })
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.getRolData(this.$route.params.id)
    },
  },
  methods: {
    checkPermission,
    collapseMenu(idMenu, status) {
      if (status) { this.$root.$emit('bv::toggle::collapse', idMenu) }
    },
    getRolData(roleId) {
      this.loading = true
      const params = { options: 'assignedPermits' }
      servicesRole.getRole(roleId, params)
        .then(({ data }) => {
          const { name, club, modules } = data.data
          this.rolPermissions.name = name
          this.rolPermissions.club = club
          this.moduleList = modules.map(e => ({
            id: e.hash,
            status: e.active ? 1 : 0,
            name: e.labelables[0].label,
            submodules: e.submodules.map(s => ({
              id: s.hash,
              status: s.active ? 1 : 0,
              name: s.labelables[0].label,
              permissions: s.permissions.map(p => ({
                id: p.hash,
                name: p.action.labelables[0].label,
                description: '',
                status: p.active ? 1 : 0,
              })),
            })),
          }))
          this.loading = false
        }).catch(error => {
          this.loading = false
          this.responseCatch(error)
        })
    },
    statusModule(module) {
      const params = {
        role_id: this.$route.params.id,
        module_id: module.id,
      }
      servicePermission.statusModule(params)
        .then(() => {
          this.getRolData(this.$route.params.id)
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    statusPermission(module, permission) {
      const params = {
        role_id: this.$route.params.id,
        module_id: module.id,
        permission_id: permission.id,
      }
      servicePermission.statusPermission(params)
        .then(() => {
          this.getRolData(this.$route.params.id)
        }).catch(error => {
          this.responseCatch(error)
        })
    },
  },
}
</script>

<style>
.custom-control.custom-switch > .custom-control-label{
    margin-bottom: 0px;
}
.icon-color-primary {
  color: #6abeff;
}
</style>
